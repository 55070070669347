import React from "react"
import Layout from "@/components/layout"
import InscricoesForm from "@/components/InscricoesOBAForm.js"

const InscricoesOBA = () => {
  return (
    <Layout>
      <InscricoesForm />
    </Layout>
  );
};

export default InscricoesOBA;
